import { instances as rpcinstance } from '@/utils/httpAaser.js'
import { instance } from '@/utils/http.js'

export const getList = function (params) {
    return instance({
        url: '/api/v1/user/mq/list',
        method: 'get',
        params
    })
}

export const cleanUnread = function (params) {
  return rpcinstance({
    url: '/api/rpc/v1/mq/makeread',
    method: 'get',
    params
  })
}