<template>
  <div class="msg_warp">
    <div class="top">
      <TopCard ref="TopCardRef" text="消息通知" :showBack="true" />
    </div>
    <div class="content">
      <div class="msg_box" v-for="(item, index) in messageList">
        <div class="left"><span class="left_time">{{item.create_time}}</span></div>
        <div class="right">
          <div class="right_box">
            <div><span class="right_title">{{item.title}}</span></div>
            <div><span class="right_content" v-html="item.content"></span></div>
          </div>
          <!-- <div class="right_detial">
            <svg width="12rem" height="22rem" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071C-0.0976311 21.3166 -0.0976311 20.6834 0.292893 20.2929L9.58579 11L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#333333"/>
            </svg>
          </div> -->
        </div>
      </div>
    </div>
  </div>
 </template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import { getList, cleanUnread  } from "@/api/message.js"

export default {
  data () {
    return {
      messageList: [],
      userInfo: {},
    }
  },
  components: {
    TopCard
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    getList().then(res => {
      this.messageList = res.data.list
    })
    cleanUnread({platform: 2, client: 1, uid: this.userInfo.user_id})
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.msg_warp {
  .content {
    padding: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40rem;
    .msg_box {
      width: 100%;
      display: flex;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
        .left_time {
          font-size: 24rem;
          font-weight: 400;
          line-height: 36rem;
          color: #666;
        }
      }
      .right {
        flex: 5;
        display: flex;
        background-color: #fff;
        border-radius: 24rem;
        padding: 32rem;
        justify-content: space-between;
        align-items: center;
        gap: 48rem;
        .right_box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 16rem;
          .right_title {
            font-size: 28rem;
            font-weight: 600;
            line-height: 42rem;
            color: #333;
          }
          .right_content {
            font-size: 24rem;
            font-weight: 400;
            line-height: 36rem;
            color: #666;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
        .right_detial {
          width: 32rem;
          height: 32rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>